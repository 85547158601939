export const projectName: string = '江苏省科技企业孵化器协会'; // 项目名
export const prefix: string | undefined = 'portal'; // 项目前缀 与 页面缓存相关
export const serverUrl = process.env.SERVER_URL || 'http://web.jsbiimpl.qgbest';
export const apiPrefix: string = '/api';
export const port = process.env.PORT || 3000;
export const UA = process.env.UA || undefined;
export const aes = process.env.AES || 'test';
export const dsn = process.env.DSN || '';
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID || '';
export const MATOMO_PROTOCOL =
  (process.env.MATOMO_PROTOCOL as 'http' | 'https' | undefined) || 'http';
export const MATOMO_HOST: string | undefined = process.env.MATOMO_HOST;
export const MATOMO_TOKEN: string | undefined = process.env.MATOMO_TOKEN;
export const MATOMO_SITE_ID: string | undefined = process.env.MATOMO_SITE_ID;
export const MATOMO_TRACKER_URL =
  process.env.MATOMO_TRACKER_URL || 'matomo.php';
export const portal_url = process.env.PORTAL_URL || '';
export const sysId = 'portal-client';
export const ADMIN_SYSID = process.env.ADMIN_SYSID || 'dbs';
export const BACK_CLIENT = process.env.BACK_CLIENT || '';
