import cookie from 'js-cookie';

export function getToken(): string | undefined {
  if (!process.browser) {
    return;
  }
  return <string>cookie.get('token');
}

export function setToken(token: string): void {
  if (!process.browser) {
    return;
  }
  cookie.set('token', token);
}

export function clearToken(): void {
  if (!process.browser) {
    return;
  }
  cookie.remove('token');
}
