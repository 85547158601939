import { LinkProps } from 'next/link';
import he from 'he';
import isString from 'lodash/isString';
import { UrlObject } from 'url';
import fromPairs from 'lodash/fromPairs';
import { BreadcrumbItem, Column, SideItem, UserInfo } from '@/typgins';
import { LinkItem } from '@/components/Layout/Links';
import { CarouselConfig } from '@/components/Carousel';
import { NewsItem } from '@/components/News/interface';
import { NextApiRequest, NextApiResponse, NextPageContext } from 'next';
import nextCookie from 'next-cookies';
import { Cookie } from 'set-cookie-parser';
import * as setCookieParser from 'set-cookie-parser';
import * as cookie from 'cookie';
import CryptoJS from 'crypto-js';
import { apiPrefix, portal_url } from '#/projectConfig';

/**
 * 拼接地址
 * @param path 地址
 */
export function join(...path: (string | undefined)[]) {
  return path.slice(1).reduce((allPath: string, curPath) => {
    if (!curPath) return allPath;
    if (allPath.charAt(allPath.length - 1) === '/') {
      if (curPath.charAt(0) === '/') {
        return allPath + curPath.slice(1);
      }
      return allPath + curPath;
    }
    if (curPath.charAt(0) === '/') {
      return allPath + curPath;
    }
    return `${allPath}/${curPath}`;
  }, path[0] || '');
}

export function isUrlObject(href: LinkProps['href']): href is UrlObject {
  return !isString(href);
}

export function isEqualHref(aspath: string, href: LinkProps['href']) {
  const pathname = aspath.split('?')[0];
  if (isUrlObject(href)) {
    return pathname === href.pathname;
  }
  return pathname === href;
}

export function isHoverHref(pathname: string, href: LinkProps['href']) {
  let hrefStr: string;
  if (isUrlObject(href)) {
    hrefStr = href.pathname as string;
  } else {
    hrefStr = href;
  }
  const path = pathname.split('?')[0].split('#')[0];
  if (hrefStr === '/') {
    return path === hrefStr;
  }
  return path.startsWith(hrefStr);
}

export function filterUndefined<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * search to query
 * @param search
 */
export function toQuery(search: string) {
  return fromPairs(Array.from(new URLSearchParams(search).entries()));
}

export function getNewImageSrc(content: string) {
  let imgSrc = '/cover.png';
  content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/, function (
    _match,
    capture,
  ) {
    imgSrc = capture;
    return '';
  });
  return imgSrc;
}

export function getImageUrl(uid?: string, placeholder?: string): string;

export function getImageUrl(uid: string, placeholder: null): string | null;
export function getImageUrl(uid: undefined, placeholder: null): null;

/**
 * 获取图片原始链接地址
 * @param uid 文件id
 * @param placeholder 图片站位
 */
export function getImageUrl(
  uid?: string,
  placeholder: string | null = '/cover.png',
) {
  if (uid?.startsWith('http')) {
    return uid;
  }
  return uid
    ? join(apiPrefix, `/file/downloadFileByUri?uri=${uid}`)
    : placeholder;
}

/**
 * 获取文件原始链接地址
 * @param uid 文件id
 * @param placeholder 图片站位
 */
export function getFileUrl(uid?: string, placeholder: string = '/cover.png') {
  if (uid?.startsWith('http')) {
    return uid;
  }
  return uid
    ? join(apiPrefix, `/file/downloadFileByUri?uri=${uid}`)
    : placeholder;
}

/**
 * 删除富文本tag
 * @param content 富文本
 */
export function remoteTag(content: string) {
  return content.replace(/<(\/){0,1}.+?>/g, '');
}

/**
 * 替换富文本转义字符
 * @param content 富文本
 */
export function replaceEscape(content: string) {
  return he.decode(content);
}

/**
 * 提取简介
 * @param content 富文本
 * @param placeholder 站位
 */
export function getIntroduction(content?: string, placeholder: string = '') {
  return content ? replaceEscape(remoteTag(content)) : placeholder;
}

export function columnToTree(
  paginationResponse: Column[],
  fatherCode = 'root',
  map = paginationResponse.reduce<Record<string, Column[]>>((prev, cur) => {
    if (prev[cur.parent_channel_code || 'root']) {
      prev[cur.parent_channel_code || 'root'].push(cur);
    } else {
      Object.assign(prev, {
        [cur.parent_channel_code || 'root']: [cur],
      });
    }
    return prev;
  }, {}),
): BreadcrumbItem[] {
  const nextRes = map[fatherCode];
  return nextRes?.map(item => ({
    label: item.channel_name,
    value: item.channel_code,
    children: columnToTree(paginationResponse, item.channel_code, map) || [],
  }));
}

export function convertChannelsToBreadcrumbs(
  paginationResponse: Column[],
  fatherCode = 'root',
  map = paginationResponse.reduce<Record<string, Column[]>>((prev, cur) => {
    if (prev[cur.parent_channel_code || 'root']) {
      prev[cur.parent_channel_code || 'root'].push(cur);
    } else {
      Object.assign(prev, {
        [cur.parent_channel_code || 'root']: [cur],
      });
    }
    return prev;
  }, {}),
): BreadcrumbItem[] {
  const nextRes = map[fatherCode];
  return (
    nextRes?.map(item => ({
      label: item.channel_name,
      value: item.channel_code,
      children:
        convertChannelsToBreadcrumbs(
          paginationResponse,
          item.channel_code,
          map,
        ) || [],
    })) || []
  );
}

export function convertContentToNews(
  content: Record<string, any>,
  options?: { coverPlaceholder?: string | null; basePath?: string },
): NewsItem {
  return {
    title: content?.title || '',
    isOutsideChain: content?.si_outside || false,
    href: content?.si_outside
      ? content?.outside_weburl || ''
      : join('/', options?.basePath, 'article', content?._id),
    type: content?.channel_code || '',
    author: content?.author || null,
    summary: content?.abstract || null,
    tags: content?.tags || null,
    source: content?.source || '未知',
    content: content?.content || null,
    publishTime: content?.release_time || null,
    pinned: content?.placed || false,
    recommended: content?.recommended || false,
    cover: (getImageUrl(content?.cover, options?.coverPlaceholder as any) as
      | string
      | null) as string | undefined,
    attids: content?.att?.split(',') || [],
  };
}

export function convertContentsToNewsList(
  items?: Record<string, any>[],
  options?: { coverPlaceholder?: string | null; basePath?: string },
): NewsItem[] {
  return items?.map(item => convertContentToNews(item, options)) || [];
}

export function convertContentsToCarousels(
  items?: Record<string, any>[],
  options?: {
    basePath?: string;
  },
): CarouselConfig[] {
  return (
    items?.map(item => ({
      desc: item.title,
      src: getImageUrl(item.cover),
      href: join(
        '/',
        options?.basePath,
        ...item.channel_code.split('-'),
        'article',
        item._id,
      ),
    })) || []
  );
}

export function convertLinkListToLinks(
  items?: Record<string, any>[],
): LinkItem[] {
  return (
    items?.map(item => ({
      title: item.link_name,
      src: getImageUrl(item.link_uri),
      href: item.link_url,
    })) || []
  );
}

export function convertChannelsToSideItems(
  items?: Record<string, any>[],
): SideItem[] {
  return (
    items?.map(item => ({
      label: item.channel_name,
      value: item.channel_code,
    })) || []
  );
}

export const getUserInfoServerSide = (
  ctx:
    | Pick<NextPageContext, 'res' | 'req'>
    | { res: NextApiResponse; req: NextApiRequest },
) => {
  return nextCookie(ctx).userInfo as UserInfo | undefined;
};

export const getTokenServerSide = (
  ctx:
    | Pick<NextPageContext, 'res' | 'req'>
    | { res: NextApiResponse; req: NextApiRequest },
) => {
  return nextCookie(ctx).token as string | undefined;
};

/**
 * Create an instance of the Cookie interface
 */
export function createCookie(
  name: string,
  value: string,
  options: cookie.CookieSerializeOptions,
): Cookie {
  let { sameSite } = options;
  if (sameSite === true) {
    sameSite = 'strict';
  }
  if (sameSite === undefined || sameSite === false) {
    sameSite = 'lax';
  }
  const cookieToSet = { ...options, sameSite };
  delete cookieToSet.encode;
  return {
    name,
    value,
    ...cookieToSet,
  };
}

/**
 * Tells whether given objects have the same properties.
 */
export function hasSameProperties(a: any, b: any) {
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i += 1) {
    const propName = aProps[i];

    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  return true;
}

/**
 * Compare the cookie and return true if the cookies have equivalent
 * options and the cookies would be overwritten in the browser storage.
 *
 * @param a first Cookie for comparison
 * @param b second Cookie for comparison
 */
export function areCookiesEqual(a: Cookie, b: Cookie) {
  let sameSiteSame = a.sameSite === b.sameSite;
  if (typeof a.sameSite === 'string' && typeof b.sameSite === 'string') {
    sameSiteSame = a.sameSite.toLowerCase() === b.sameSite.toLowerCase();
  }

  return (
    hasSameProperties(
      { ...a, sameSite: undefined },
      { ...b, sameSite: undefined },
    ) && sameSiteSame
  );
}
/**
 * Sets a cookie.
 *
 * @param ctx NextJS page or API context, express context, null or undefined.
 * @param name The name of your cookie.
 * @param value The value of your cookie.
 * @param options Options that we pass down to `cookie` library.
 */
export function setCookie(
  ctx:
    | Pick<NextPageContext, 'res' | 'req'>
    | { res: NextApiResponse; req: NextApiRequest }
    | null
    | undefined,
  name: string,
  value: string,
  options: cookie.CookieSerializeOptions = {},
) {
  // SSR
  if (ctx?.res?.getHeader) {
    // Check if response has finished and warn about it.
    if (ctx?.res?.writableEnded) {
      console.warn(`Not setting "${name}" cookie. Response has finished.`);
      console.warn(`You should set cookie before res.send()`);
      return;
    }

    /**
     * Load existing cookies from the header and parse them.
     */
    let cookies = ctx.res.getHeader('Set-Cookie') || [];

    if (typeof cookies === 'string') cookies = [cookies];
    if (typeof cookies === 'number') cookies = [];

    /**
     * Parse cookies but ignore values - we've already encoded
     * them in the previous call.
     */
    const parsedCookies = setCookieParser.parse(cookies, {
      decodeValues: false,
    });

    /**
     * We create the new cookie and make sure that none of
     * the existing cookies match it.
     */
    const newCookie = createCookie(name, value, options);
    const cookiesToSet: string[] = [];

    parsedCookies.forEach((parsedCookie: Cookie) => {
      if (!areCookiesEqual(parsedCookie, newCookie)) {
        /**
         * We serialize the cookie back to the original format
         * if it isn't the same as the new one.
         */
        const serializedCookie = cookie.serialize(
          parsedCookie.name,
          parsedCookie.value,
          {
            // we prevent reencoding by default, but you might override it
            encode: (val: string) => val,
            ...(parsedCookie as cookie.CookieSerializeOptions),
          },
        );

        cookiesToSet.push(serializedCookie);
      }
    });
    const serializeCookie = cookie.serialize(name, value, options);
    cookiesToSet.push(cookie.serialize(name, value, options));
    // Update the header.
    ctx.res.setHeader('Set-Cookie', cookiesToSet);
    if (ctx.req?.headers) {
      if (options.maxAge === -1) {
        ctx.req.headers.cookie = ctx.req.headers.cookie?.replace(
          new RegExp(`${name}=[^;]*;\\s|(;\\s)?${name}=[^;]*$`, 'i'),
          '',
        );
      } else {
        ctx.req.headers.cookie = ctx.req.headers.cookie
          ? `${ctx.req.headers.cookie};${serializeCookie}`
          : serializeCookie;
      }
    }
  }

  // Browser
  if (process.browser) {
    if (options && options.httpOnly) {
      throw new Error('Can not set a httpOnly cookie in the browser.');
    }

    document.cookie = cookie.serialize(name, value, options);
  }

  return {};
}

/**
 * Destroys a cookie with a particular name.
 *
 * @param ctx NextJS page or API context, express context, null or undefined.
 * @param name Cookie name.
 * @param options Options that we pass down to `cookie` library.
 */
export function destroyCookie(
  ctx:
    | Pick<NextPageContext, 'res' | 'req'>
    | { res: NextApiResponse; req: NextApiRequest }
    | null
    | undefined,
  name: string,
  options?: cookie.CookieSerializeOptions,
) {
  /**
   * We forward the request destroy to setCookie function
   * as it is the same function with modified maxAge value.
   */
  return setCookie(ctx, name, '', { ...(options || {}), maxAge: -1 });
}

export function getRedirectUrl(current_url?: string) {
  if (process.browser) {
    return join(
      portal_url,
      `/login?redirect=${current_url || window.location.href}`,
    );
  }
  return current_url ? join(portal_url, `/login?redirect=${current_url}`) : '/';
}

export function isIe(): boolean {
  if (process.browser) {
    return false || !!(document as any).documentMode;
  }
  return false;
}

const key = '78CB3DDC87BF4A82D5AC3913825E1ED9';
// 加密
export function encrypt(text: string) {
  const ciphertext = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return CryptoJS.enc.Base64.stringify(ciphertext.ciphertext);
}
// 解密
export function decrypt(text: string) {
  const ciphertext = CryptoJS.AES.encrypt('', CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  const decrypted = CryptoJS.AES.decrypt(
    {
      ...ciphertext,
      ciphertext: CryptoJS.enc.Base64.parse(text),
    },
    CryptoJS.enc.Utf8.parse(key),
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    },
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
}
