/*
 * @Author: your name
 * @Date: 2020-12-11 10:50:42
 * @LastEditTime: 2020-12-18 09:37:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /portal-client/src/service/user.ts
 */
import { UserInfoDTO } from '@/typgins';
import request, { ReqResponse } from '@/utils/request';
import md5 from 'js-md5';
import { encrypt } from '@/utils/utils';
import { sysId } from '#/projectConfig';

export const ErrorCodeMap: Record<string, string> = {
  '0': '请求成功',
  '1': '解析配置文件失败',
  '2': '获取服务端数据失败',
  '3': '解析服务端数据失败',
  '8': '解析命令行数据失败',
  '11': '加密狗密码错误(PKCS1String签名失败)',
  '12': 'PKCS1Verify验签失败',
  '13': 'PublicEncrypt加密失败',
  '14': 'PrivateDecrypt解密失败',
  '15': 'PKCS7String签名失败',
  '16': 'SKF_SignMessageUseGMT00092012签名失败',
  '17': 'SKF_VerifyMessageUseGMT00092012验签失败',
  '18': 'EnvSeal加密失败',
  '19': 'EnvOpen解密失败',
  '401': '请使用加密狗登录(枚举证书失败)',
  '402': '构造证书对象失败',
  '403': '选择证书失败',
  '404': '上传数据失败',
  '1000': '推送数据失败',
  '9999': '服务器异常',
};

export async function addUser(
  userInfo: any,
  mobile: string,
  verification_code: string,
) {
  return request.post(`/user/personManage/register`, userInfo, {
    headers: {
      mobile,
      mobileverifycode: verification_code,
    },
  });
}

export function getGuestUid() {
  return request.get('/user/auth/getGuestUid');
}

export function getCurUser(token?: string) {
  return request.get<ReqResponse<UserInfoDTO>>('/user/auth/queryCurrentInfo', {
    headers: {
      token,
    },
  });
}

export async function login(
  username: string,
  password: string,
  guestUid: string,
  verifyCode: string,
  mySysId = sysId,
  usbKeyNum?: string,
  number?: string,
  messDigest?: string,
) {
  return request.post(
    `/user/auth/newLogin`,
    {
      username,
      password: md5(password),
      sysId: mySysId,
      usbKeyNum,
      messDigest,
      number,
    },
    {
      headers: {
        verifyCode,
        guestUid,
      },
    },
  );
}

// 退出登录
export function logout() {
  return request.get('/user/auth/logout');
}

export function checkUserIsNeedUsbKey(username: string) {
  return request.get(`/user/auth/checkUserIsNeedUsbKey?username=${username}`);
}
// 获取随机数
export function getSrcData() {
  return request.get('/user/auth/getSrcData');
}
// 签名
export function getData(unique: string, operatortype: string) {
  return request.get<ReqResponse<string>>(
    `/jmg/gzwebsignatureservice/business/get.do?unique=${unique}&operatortype=${operatortype}`,
    {
      transformResponse(res) {
        if (!res) {
          return {
            code: 501,
            msg: '请求异常',
          };
        }
        const result = decode64(res);
        const parser = new DOMParser();
        // 解析xml
        const xmlDoc = parser.parseFromString(result, 'text/xml');
        const code = xmlDoc.querySelector('code')?.textContent;
        const msg = xmlDoc.querySelector('msg')?.textContent;
        const data = xmlDoc.querySelector('data')?.textContent;
        const errorcode = xmlDoc.querySelector('errorcode')
          ?.textContent as string;
        if (code === '0000') {
          return {
            code: errorcode === '0' ? 200 : 500,
            data,
            msg: ErrorCodeMap[errorcode],
          };
        }

        return {
          code: 200,
          msg,
        };
      },
    },
  );
}

// 查询账户是否存在
export async function checkAccountIsAvalible(account: string) {
  return request.get(
    `/user/auth/checkUserAccountExists?userAccount=${account}`,
    {},
  );
}

// 通过手机号发送短信
export function getVerificationByMobile(mobile: string) {
  return request.get(`/common/msg/getVerificationCode/${encrypt(mobile)}`, {
    headers: {
      projectId: 'B02012c98d228-75ab-11eb-bfb5-6a24c7f29f95',
    },
  });
}

// 帐号检查
export function checkAccount(
  user_account: string,
  guestuid: string,
  verifycode: string,
) {
  return request.get(`/common/userMobile?user_account=${user_account}`, {
    headers: {
      projectId: 'B02012c98d228-75ab-11eb-bfb5-6a24c7f29f95',
      guestuid,
      verifycode,
    },
  });
}
// 检查短信
export function checkVerification(mobile: string, verification_code: string) {
  return request.post(
    '/common/checkMobileVerifyCode',
    {},
    {
      headers: {
        mobile,
        mobileverifycode: verification_code,
      },
    },
  );
}
// 发送短信
export function getVerification(user_account: string) {
  return request.post(
    '/common/user/sendSms',
    {
      user_account,
    },
    {
      headers: {
        projectId: 'B02012c98d228-75ab-11eb-bfb5-6a24c7f29f95',
      },
    },
  );
}
// 重置密碼
export async function resetPassword(
  user_account: string,
  password: string,
  uuid: string,
) {
  return request.put(
    `/common/user/resetPassword`,
    {
      user_account,
      password: md5(password),
    },
    {
      headers: {
        uuid,
        projectId: 'B02012c98d228-75ab-11eb-bfb5-6a24c7f29f95',
      },
    },
  );
}
